/* Google Fonts - icons */
@import url('https://fonts.googleapis.com/icon?family=Material+Icons+Round');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');

/* Mulish Fonts */
@font-face {
  font-family: "MulishRegular";
  src: url("./assets/fonts/Mulish-Regular.ttf");
  font-weight: 400;
}

@font-face {
  font-family: "MulishMedium";
  src: url("./assets/fonts/Mulish-Medium.ttf");
  font-weight: 500;
}

@font-face {
  font-family: "MulishBold";
  src: url("./assets/fonts/Mulish-Bold.ttf");
  font-weight: 700;
}

@font-face {
  font-family: "MulishExtraBold";
  src: url("./assets/fonts/Mulish-ExtraBold.ttf");
  font-weight: 800;
}

/* forces size calculation from the border instead of padding  */
* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

html,
.App,
#root {
  height: 100%;
  width: 100%;
}

.App {
  display: flex;
  flex-direction: column;
  width: 100vw;
  background-color: #e6e6e6;
}

body {
  height: 100%;
  font-size: 16px;
  margin: 0;
  font-family: 'MulishRegular', sans-serif;
  padding: 0;
  width: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

button {
  cursor: pointer;
  font-family: 'MulishRegular', sans-serif;
}

input,
textarea {
  font-family: 'MulishRegular', sans-serif;
}

h1 {
  font-size: 65px;
  font-family: 'MulishExtraBold';
}

h2 {
  font-size: 48px;
  font-family: 'MulishMedium';
}

h3 {
  font-size: 40px;
  font-family: 'MulishBold';
}

h4 {
  font-size: 32px;
  font-family: 'MulishBold';
}

h5 {
  font-size: 28px;
  font-family: 'MulishBold';
}

h6 {
  font-size: 22px;
  font-family: 'MulishMedium';
}

.subtitle {
  font-size: 20px;
  font-family: 'MulishRegular';
}

.subtitleB {
  font-size: 20px;
  font-family: 'MulishBold';
}

.body {
  font-size: 16px;
  font-family: 'MulishRegular';
}

.bodyB {
  font-size: 16px;
  font-family: 'MulishBold';
}

.caption {
  font-size: 14px;
  font-family: 'MulishMedium';
  letter-spacing: 0.4px;
}

.small {
  font-size: 10px;
  font-family: 'MulishBold';
  letter-spacing: 1.5px;
}

h1, h2, h3, h4, h5, h6,
.subtitle, .subtitleB, .body, .bodyB, .caption, .small {
  line-height: 120%;
}

h1, h2, h3, h4, h5, h6,
.subtitle, .subtitleB, .body, .bodyB {
  letter-spacing: 0.5px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

/* Tablet and mobile */
@media (max-width: 1023px) {
  h1 {
    font-size: 34px;
    font-family: 'MulishExtraBold';
  }

  h2 {
    font-size: 28px;
    font-family: 'MulishMedium';
  }

  h3 {
    font-size: 24px;
    font-family: 'MulishBold';
  }

  h4 {
    font-size: 22px;
    font-family: 'MulishBold';
  }

  h5 {
    font-size: 20px;
    font-family: 'MulishBold';
  }

  h6 {
    font-size: 18px;
    font-family: 'MulishMedium';
  }

  .subtitle {
    font-size: 16px;
    font-family: 'MulishRegular';
  }

  .subtitleB {
    font-size: 16px;
    font-family: 'MulishBold';
  }

  .body {
    font-size: 14px;
    font-family: 'MulishRegular';
  }

  .bodyB {
    font-size: 14px;
    font-family: 'MulishBold';
  }

  .caption {
    font-size: 11px;
    font-family: 'MulishMedium';
  }

  .small {
    font-size: 9px;
    font-family: 'MulishBold';
  }

  .subtitle, .subtitleB {
    line-height: 140%;
  }
}